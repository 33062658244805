import React from 'react';

const LandingComponent = () => {
  const documentationHtml = `
    <div class="landing-component">
      <h1>Fulfil Release Dashboard</h1>
      <p>The Fulfil Release Dashboard is a tool designed to manage and monitor the deployment of services across different environments.</p>
      
      <h2>How to Use</h2>
      <ol>
        <li><strong>Select a Service</strong>: Upon opening the dashboard, you'll see a list of available services. Click on a service to view its details.</li>
        <li><strong>View Service Details</strong>: Once a service is selected, you'll see information about its deployments, including:
          <ul>
            <li>Build information</li>
            <li>Tags</li>
            <li>Timestamps</li>
            <li>Deployment history</li>
            <li>Release steps</li>
          </ul>
        </li>
        <li><strong>Deploy a Service</strong>:
          <ul>
            <li>Click the "Deploy" button next to the desired build</li>
            <li>Select the target environments</li>
            <li>Confirm the deployment</li>
          </ul>
        </li>
        <li><strong>Monitor Deployment Progress</strong>: After initiating a deployment, you can track its progress in real-time.</li>
        <li><strong>Manage Services</strong>: Administrators can add, edit, or remove services using the "Manage Services" feature.</li>
      </ol>
      
      <h2>Features</h2>
      <ul>
        <li>View a list of services</li>
        <li>Monitor deployment status for each service</li>
        <li>Deploy services to selected environments</li>
        <li>Track release steps for each deployment</li>
        <li>Manage service configurations</li>
      </ul>

      <h2>Additional Information</h2>
      <ul>
        <li><strong>Requires the "release-admin" dashboard permission</strong></li>
        <li>Release steps can be toggled to mark progress</li>
        <li>Pod information is displayed for each service, including the ability to restart pods</li>
      </ul>
      
      <p>For more detailed information or support ping #cloud-team.</p>
    </div>
  `;

  return (
    <div dangerouslySetInnerHTML={{ __html: documentationHtml }} />
  );
};

export default LandingComponent;
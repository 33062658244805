const hostname = window.location.hostname;

const config = {
  production: {
    API_BASE_URL: 'https://release-dashboard-backend.fulfil.store',
    KEYCLOAK_URL: 'https://auth-prod.fulfil-api.com/auth/realms/fulfil/protocol/openid-connect/auth',
  },
  local: {
    API_BASE_URL: 'http://localhost:4000',
    KEYCLOAK_URL: 'https://auth-staging.np-fulfil-api.com/auth/realms/fulfil/protocol/openid-connect/auth',
  }
};

const selectedConfig = hostname === 'localhost' ? config.local : config.production;

export default selectedConfig;

import React, { useEffect, useState } from 'react';
import xhr from '../xhr';
import config from '../config';
import { Link } from 'react-router-dom';
import { handleLogout } from '../logout';

const ServiceList = () => {
  const [services, setServices] = useState([]);

  useEffect(() => {
    console.info('config.API_BASE_URL:', config.API_BASE_URL);
    xhr.get(`${config.API_BASE_URL}/api/services`)
      .then(response => setServices(response.data))
      .catch(error => console.error('Error fetching services:', error));
  }, []);

  return (
    <div className="service-list">
      {services.map(service => (
        <Link to={`/service/${service.id}`} key={service.id}>
          <button>{service.service_name}</button>
        </Link>
      ))}

      {/* <button onClick={handleLogout} className="logout-button">Logout</button> Logout button */}
      <Link to="/manage-services">
        <button className="manage-services-button">Manage Services</button>
      </Link>
    </div>
  );
};

export default ServiceList;

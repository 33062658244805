import axios from 'axios';
import config from './config';
import { refreshToken, getKeycloakUrl } from './auth';

const xhr = axios.create({
  baseURL: config.API_BASE_URL,
});

// Request interceptor
xhr.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem('kc-token');
    if (token) {
      config.headers['Authorization'] = `Bearer ${token}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

// Response interceptor
xhr.interceptors.response.use(
  (response) => response,
  async (error) => {
    const originalRequest = error.config;

    if (error.response.status === 403 && !originalRequest._retry) {
      originalRequest._retry = true;

      try {
        const newToken = await refreshToken();
        originalRequest.headers['Authorization'] = `Bearer ${newToken}`;
        return xhr(originalRequest);
      } catch (refreshError) {
        // If refresh fails, redirect to Keycloak login
        window.location.href = getKeycloakUrl();
        return Promise.reject(refreshError);
      }
    }

    return Promise.reject(error);
  }
);

export default xhr;
import React, { useState, useEffect } from 'react';
import { Snackbar, Alert } from '@mui/material';
import './ReleaseModal.css';
import DeploymentProgress from './DeploymentProgress';

const ReleaseModal = ({ commit, onClose, onSubmit, service }) => {
  const [selectedEnvironments, setSelectedEnvironments] = useState([]);
  const [isDeploying, setIsDeploying] = useState(false);
  const [releaseId, setReleaseId] = useState(null);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');

  useEffect(() => {
    if (service && service.facilities) {
      setSelectedEnvironments([]);
    }
  }, [service]);

  const handleEnvironmentChange = (env) => {
    setSelectedEnvironments(prevState =>
      prevState.includes(env) ? prevState.filter(e => e !== env) : [...prevState, env]
    );
  };

  const handleSubmit = () => {
    if (selectedEnvironments.length > 0) {
      setIsDeploying(true);
      onSubmit(commit.tags[0], selectedEnvironments)
        .then(response => {
          if (response && response.releaseId) {
            console.log('Release ID received:', response.releaseId);
            setReleaseId(response.releaseId);
          } else {
            throw new Error('No release ID received');
          }
        })
        .catch(error => {
          console.error('Error creating release:', error);
          setSnackbarMessage(error.toString());
          setSnackbarOpen(true);
          setIsDeploying(false);
        });
    }
  };

  const handleSnackbarClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setSnackbarOpen(false);
  };

  const isSubmitDisabled = selectedEnvironments.length === 0;

  return (
    <div className="modal">
      <div className="modal-content">
        {!isDeploying ? (
          <>
            <button className="close-button" onClick={onClose}>Close</button>
            <h2>Release {commit.tags[0]}</h2>
            <p>Select environments to release to:</p>
            {service && service.facilities && service.facilities.map(env => (
              <div key={env} className="left">
                <input
                  type="checkbox"
                  id={env}
                  name={env}
                  value={env}
                  onChange={() => handleEnvironmentChange(env)}
                />
                <label htmlFor={env}>{env}</label>
              </div>
            ))}
            <button 
              className={`red-button ${isSubmitDisabled ? 'disabled' : ''}`}
              onClick={handleSubmit}
              disabled={isSubmitDisabled}
            >
              Release this build to the selected environments
            </button>
          </>
        ) : (
          <>
            {releaseId ? (
              <DeploymentProgress releaseId={releaseId} onClose={onClose} />
            ) : (
              <div>
                <img src="/animated-progress.gif" alt="Deployment in progress" style={{ width: '100px', display: 'block', margin: '-20px auto -16px' }}/>
                Creating release...</div>
            )}
          </>
        )}
      </div>
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
      >
        <Alert onClose={handleSnackbarClose} severity="error" sx={{ width: '100%' }}>
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </div>
  );
};

export default ReleaseModal;
import React, { useState, useEffect } from 'react';
import xhr from '../xhr';
import ReleaseModal from './ReleaseModal';
import config from '../config';
import Icon from '@mui/material/Icon';
import ContentCutIcon from '@mui/icons-material/ContentCut';
import ChecklistIcon from '@mui/icons-material/Checklist';
import ApartmentIcon from '@mui/icons-material/Apartment';
import AirIcon from '@mui/icons-material/Air';
import PrecisionManufacturingIcon from '@mui/icons-material/PrecisionManufacturing';
import RocketLaunchIcon from '@mui/icons-material/RocketLaunch';
import CertifiedIcon from '@mui/icons-material/NewReleases';
import Tooltip from '@mui/material/Tooltip';

const steps = [
  { name: 'release cut', icon: ContentCutIcon },
  { name: 'testbook complete', icon: ChecklistIcon },
  { name: 'tested on sim', icon: ApartmentIcon },
  { name: 'tested on whis', icon: AirIcon },
  { name: 'pio stability IT', icon: PrecisionManufacturingIcon },
  { name: 'certified', icon: CertifiedIcon },
  { name: 'released', icon: RocketLaunchIcon }
];

const CommitRow = ({ commit, refreshCommits, isContinuousDeployment, deployedMap, service }) => {
  const [showModal, setShowModal] = useState(false);
  const [stepStatuses, setStepStatuses] = useState(() => {
    const statuses = {};
    steps.forEach(step => {
      statuses[step.name] = commit.steps?.[step.name] ? { completed: true, timestamp: commit.steps[step.name] } : { completed: false };
    });
    return statuses;
  });

  const handleAction = () => {
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  const handleSubmit = (tag, selectedEnvironments) => {
    const payload = {
      service_id: commit.service_id,
      build: commit.digest,
      tag: tag,
      environments: selectedEnvironments
    };
    return xhr.post(`${config.API_BASE_URL}/api/releases`, payload)
      .then(response => {
        refreshCommits();
        return response.data;
      })
      .catch(error => {
        console.error('Error creating release:', error);
        return Promise.reject(error.response?.data?.error || 'An error occurred while creating the release');
      });
  };

  const toggleStep = (step_name) => {
    xhr.post(`${config.API_BASE_URL}/api/release-steps/toggle-step`, {
      image_digest: commit.digest,
      step_name
    })
      .then(response => {
        setStepStatuses({
          ...stepStatuses,
          [step_name]: { completed: response.data.completed, timestamp: response.data.timestamp }
        });
      })
      .catch(error => console.error('Error toggling step:', error));
  };

  const { digest, tags, timestamp, releases } = commit;

  const formatTimestamp = (timestamp) => {
    const date = new Date(timestamp);
    const options = {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
      hour: '2-digit',
      minute: '2-digit',
      second: '2-digit',
      hour12: true
    };
    return date.toLocaleString('en-US', options).replace(/,/g, '').replace(/:([0-5]\d)\s/, '-$1 ').toLowerCase();
  };

  const formatDigest = (digest) => digest.replace('sha256:', '').substring(0, 12);

  return (
    <>
      <tr>
        <td>
          <img src='/docker-ico.png' alt="docker icon" width="18" height="18" style={{ verticalAlign: 'middle', marginRight: '5px' }} />
          {formatDigest(digest)}
        </td>
        <td>
          {(tags || []).map(tag => (
            <span key={tag} className={`tag-pill ${tag === 'latest' ? 'latest-pill' : ''}`}>
              {tag}
            </span>
          ))}
          {tags && tags.map(tag => (
            deployedMap[tag] && deployedMap[tag].map(location => (
              <span key={`${tag}-${location}`} className="tag-pill deployed-pill">
                {location}
              </span>
            ))
          ))}
        </td>
        <td>{timestamp ? formatTimestamp(timestamp.datetime || timestamp) : 'Invalid date'}</td>
        {!isContinuousDeployment && (
          <>
            <td>
              {(releases || []).map(r => (
                <span key={r.environment} className="tag-pill">
                  {r.environment}
                </span>
              ))}
            </td>
            <td>
              <button onClick={handleAction}>Deploy</button>
            </td>
          </>
        )}
        <td>
          {steps.map(step => {
            const StepIcon = step.icon;
            return (
              <Tooltip key={step.name} title={stepStatuses[step.name]?.completed ? `${step.name} completed ${formatTimestamp(stepStatuses[step.name].timestamp)}` : step.name}>
                <StepIcon
                  onClick={() => toggleStep(step.name)}
                  style={{
                    cursor: 'pointer',
                    marginRight: '5px',
                    color: stepStatuses[step.name]?.completed ? '#2CB673' : 'grey'
                  }}
                />
              </Tooltip>
            );
          })}
        </td>
      </tr>
      {showModal && <ReleaseModal commit={commit} onClose={handleCloseModal} onSubmit={handleSubmit} service={service} />}
    </>
  );
};

export default CommitRow;
import React, { useEffect, useState } from 'react';
import { Snackbar, Alert } from '@mui/material';
import xhr from '../xhr';
import CommitRow from './CommitRow';
import InfiniteScroll from 'react-infinite-scroll-component';
import config from '../config';
import LandingComponent from './Landing';

const ServiceDetails = ({ serviceId }) => {
  const [service, setService] = useState(null);
  const [images, setImages] = useState([]);
  const [page, setPage] = useState(1);
  const [hasMore, setHasMore] = useState(true);
  const [podsInfo, setPodsInfo] = useState([]);
  const [deployedMap, setDeployedMap] = useState({});
  const [pollingInterval, setPollingInterval] = useState(null);
  const [currentService, setCurrentService] = useState(null);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');

  useEffect(() => {
    setPodsInfo([]);
    setImages([]);
    if (serviceId) {
      fetchService(serviceId);
    }

    return () => {
      clearInterval(pollingInterval);
    };
  }, [serviceId]);

  useEffect(() => {
    if (service) {
      startPolling(service);
    }
  }, [service, currentService]);

  const fetchService = (id) => {
    xhr.get(`${config.API_BASE_URL}/api/services/${id}`)
      .then(response => {
        setService(response.data);
        fetchImages(id, 1, true);
      })
      .catch(error => console.error('Error fetching service:', error));
  };

  const fetchImages = (serviceId, page, reset = false) => {
    xhr.get(`${config.API_BASE_URL}/api/images?service_id=${serviceId}&page=${page}&limit=50`)
      .then(response => {
        const newImages = response.data;
        setImages(reset ? newImages : [...images, ...newImages]);
        setHasMore(newImages.length === 50);
        setPage(page);
      })
      .catch(error => console.error('Error fetching images:', error));
  };

  const fetchPodsInfo = (service) => {
    if (currentService !== service.k8sSelectorLabel) {
      setPodsInfo([]);
      setDeployedMap({});
      setCurrentService(service.k8sSelectorLabel);
    }
  
    if (service.facilities && service.k8sSelectorLabel) {
      const facilities = service.facilities;
      facilities.forEach(facility => {
        xhr.get(`${config.API_BASE_URL}/api/k8s/pods`, {
          params: {
            facility,
            namespace: 'fulfil-prod',
            labelSelector: service.k8sSelectorLabel
          }
        })
          .then(response => {
            if (currentService === service.k8sSelectorLabel) {
              setPodsInfo(prevPodsInfo => {
                const existingFacilityIndex = prevPodsInfo.findIndex(podInfo => podInfo.facility === facility && podInfo.labelSelector === service.k8sSelectorLabel);
                let newPodsInfo = [...prevPodsInfo];
                const newPodInfo = {
                  facility,
                  labelSelector: service.k8sSelectorLabel,
                  totalPods: response.data.totalPods,
                  podsDetails: response.data.podsDetails
                };
  
                if (existingFacilityIndex >= 0) {
                  newPodsInfo[existingFacilityIndex] = newPodInfo;
                } else {
                  newPodsInfo.push(newPodInfo);
                }
                return newPodsInfo.filter(a => a.labelSelector === service.k8sSelectorLabel)
                  .sort((a, b) => a.facility.localeCompare(b.facility));
              });
  
              response.data.podsDetails.forEach(pod => {
                pod.containers.forEach(container => {
                  const version = extractVersion(container.image);
                  setDeployedMap(prevMap => {
                    const newMap = { ...prevMap };
                    if (!newMap[version]) {
                      newMap[version] = [];
                    }
                    if (!newMap[version].includes(facility)) {
                      newMap[version].push(facility);
                    }
                    return newMap;
                  });
                });
              });
            }
          })
          .catch(error => console.error(`Error fetching pods info for ${facility}:`, error));
      });
    }
  };

  const startPolling = (service) => {
    clearInterval(pollingInterval);
    fetchPodsInfo(service);
    const interval = setInterval(() => fetchPodsInfo(service), 1000);
    setPollingInterval(interval);
  };

  const handleRestart = (facility, podName) => {
    xhr.post(`${config.API_BASE_URL}/api/k8s/restart`, { facility, namespace: 'fulfil-prod', podName })
    .then(response => {
      setSnackbarMessage(`Pod ${podName} in facility ${facility} has been restarted.`);
      setSnackbarOpen(true);
    })
    .catch(error => {
      console.error('Error restarting pod:', error);
      setSnackbarMessage(`Failed to restart pod ${podName} in facility ${facility}.`);
      setSnackbarOpen(true);
    });
  };

  const handleSnackbarClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setSnackbarOpen(false);
  };

  const extractVersion = (image) => {
    return image.split(':')[1];
  };

  return (
    <div className="service-details">
      {!service ? (
        <LandingComponent />
      ) : (
        <>
          {podsInfo.length > 0 && (
            <div className="pods-info">
              <h2>{service.service_name} Pod Information</h2>
              <table className="t1">
                <thead>
                  <tr className="t1r">
                    <td className="t1rd t1rd1">Facility</td>
                    <td className="t1rd">Pod Name</td>
                    <td className="t1rd">Image</td>
                    <td className="t1rd">Age</td>
                    <td className="t1rd">Status</td>
                    <td className="t1rd t1rd2">Restart</td>
                  </tr>
                </thead>
                <tbody>
                  {podsInfo.map((info, index) => (
                    info.podsDetails.map(pod => (
                      <tr key={`${index}-${pod.name}`}>
                        <td>{info.facility}</td>
                        <td>{pod.name}</td>
                        <td>
                          {pod.containers.map(container => (
                            <div key={container.image}>
                              {extractVersion(container.image)}
                            </div>
                          ))}
                        </td>
                        <td>{pod.age}</td>
                        <td>{pod.status}</td>
                        <td>
                          <button
                            style={{ height: '25px' }}
                            onClick={() => handleRestart(info.facility, pod.name)}
                          >
                            Restart
                          </button>
                        </td>
                      </tr>
                    ))
                  ))}
                </tbody>
              </table>
            </div>
          )}
          <br />
          <h2>{service.service_name} Images</h2>
          <InfiniteScroll
            dataLength={images.length}
            next={() => {
              fetchImages(serviceId, page + 1);
            }}
            hasMore={hasMore}
            loader={<h4>Loading...</h4>}
            endMessage={<p>Loading...</p>}
          >
            <table>
              <thead>
                <tr>
                  <th>Build</th>
                  <th>Tags</th>
                  <th>Timestamp</th>
                  {!service?.is_continuous_deployment && <th>History</th>}
                  {!service?.is_continuous_deployment && <th>Actions</th>}
                  <th>Release Steps</th>
                </tr>
              </thead>
              <tbody>
                {images.map(image => (
                  <CommitRow
                    key={image.digest}
                    commit={{ ...image, service_id: serviceId }}
                    refreshCommits={() => fetchImages(serviceId, 1, true)}
                    isContinuousDeployment={service?.is_continuous_deployment}
                    deployedMap={deployedMap}
                    service={service}
                  />
                ))}
              </tbody>
            </table>
          </InfiniteScroll>
          <Snackbar
            open={snackbarOpen}
            autoHideDuration={6000}
            onClose={handleSnackbarClose}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'center',
            }}
          >
            <Alert onClose={handleSnackbarClose} severity="warning" sx={{ width: '100%' }}>
              {snackbarMessage}
            </Alert>
          </Snackbar>
        </>
      )}
    </div>
  );
};

export default ServiceDetails;